import ".././stylesheets/application.scss";
import * as ActiveStorage from "@rails/activestorage";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "material-icons/iconfont/material-icons.css";
import "@hotwired/turbo-rails";
import mixpanel from "mixpanel-browser";
import Appsignal from "@appsignal/javascript";

const debugFlag = import.meta.env.RAILS_ENV === "development";

Turbo.start();

mixpanel.init(import.meta.env.MIXPANEL_TOKEN, {
  api_host: `${window.location.protocol}//${window.location.host}/api/mixpanel`,
  debug: debugFlag,
  ignore_dnt: true,
  loaded: function (mixpanel) {
    const zest_uuid = getCookie("zest_uuid");
    if (zest_uuid != null) {
      mixpanel.identify(zest_uuid);
    }
    // This helps with bot identification as most web crawlers don't actually render the site.
    // Also takes advantage of default props mixpanel sends along with this such as user location and user agent.
    mixpanel.people.set({
      loaded_js: true,
    });

    setProfileProperties();
  },
});

const appsignal = new Appsignal({
  key: import.meta.env.APPSIGNAL_FRONTEND_KEY,
});

window.appsignal = appsignal;

ActiveStorage.start();
import "../controllers/global";

// This is an escape hatch for Turbo 7.3 to allow us to visit a response that Turbo would otherwise ignore.
// https://github.com/hotwired/turbo/pull/863#issuecomment-1499571361
document.addEventListener("turbo:frame-missing", function (event) {
  event.preventDefault();
  event.detail.visit(event.detail.response);
});

function setProfileProperties() {
  const set_once_properties = {};
  const set_properties = {};
  const utm_params = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
  ];
  utm_params.forEach((param) => {
    const value = getQueryParameter(param);
    if (value) {
      set_once_properties[`$${param}_first_touch`] = value;
      set_properties[`$${param}_last_touch`] = value;
    }
  });

  // The Mixpanel library updates Initial Referer values if a user clears cookies or uses a different device.
  // We'll track an additional set_once property that never gets updated.
  set_once_properties["first_touch_referrer"] = document.referrer || "$direct";
  set_once_properties["first_touch_referring_domain"] =
    referringDomain(document.referrer) || "$direct";

  mixpanel.people.set_once(set_once_properties);
  mixpanel.people.set(set_properties);
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  } else {
    return null;
  }
}

function getQueryParameter(name) {
  const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

// Matching logic from Mixpanel JS library.
function referringDomain(referrer) {
  const split = referrer.split("/");
  if (split.length >= 3) {
    return split[2];
  }
  return "";
}
